import React from 'react';
import styled from '@emotion/styled';
import {post} from 'axios';

import qs from 'qs';

import { RichText } from 'prismic-reactjs';
import Loader from 'components/Loader';

import { Layout, SEO, Grid, Box, Text } from 'components';
import VideoPlayer from 'components/VideoPlayer';

import Input from 'components/events/Input';
import { colors, mediaQueries } from 'styles/theme';

import EventsData from '../data/events';
import DeleteIcon from 'images/delete-icon.svg';

import Chat from '../components/events/Chat';

const IntroContainer = styled(Box)`
  width: 100%;
  position: relative;
  background: #fafafa;
  padding: 5% 10%;
  background-color: ${colors.black};
`;

const Intro = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;

  position: sticky;
  top: 0;
  left: 0;

  width: 100%;
  // height: 40vh;

  transition: background-color 0.2s ease-out;

  ${mediaQueries.sm} {
    // height: 60vh;
    min-height: 500px;
  }

  &:not(:first-of-type) {
    border-top: 1px solid ${colors.gray};
  }
`;

const BorderBox = styled(Box)`
  border-bottom: 1px solid ${colors.gray};
`;

const SocialHeader = styled(Text)`
  text-transform: uppercase;
  grid-column: ${['2 / span 10']};
  text-align: center;
  padding-bottom: 35px;
`;

SocialHeader.defaultProps = {
  size: ['1.1x', '0.8x'],
};

const UpcomingText = styled(Text)`
  &::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '\\2690';
    font-weight: 500;
    margin-right: 10px;
  }
`;

const RsvpButton = styled(Text)`
  color: ${colors.lime};
  padding: 18px 25px;

  &::after {
    content: '\\2199';
    display: inline-block;
    font-size: inherit;
    margin-left: 10px;
  }
`;

const RsvpForm = styled(Box)`
  // position: absolute;
  // z-index: 1000;
  // top: -55px;
  color: ${colors.white};
  left: 0;
  right: 0;
  // background-color: ${colors.white};
  // border-radius: 32px;
  padding: 12px 24px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 0 auto;

  ${mediaQueries.sm} {
    top: 0;
  }
`;

const RsvpCloseIcon = styled('div')`
  background-image: url('${DeleteIcon}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-origin: content-box;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  height: 15px;
  width: 15px;
`;

const RsvpSubmitBtn = styled(Box)`
  display: inline-block;
  border-radius: 20px;
  background-color: ${colors.whiteDimmed02};
  color: ${colors.whiteDimmed01};
  padding: 18px 22px;
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  margin-top: 32px;

  &::${ props => props.close ? 'before' : 'after'} {
    display: inline-block;
    width: 20px;
    height: 20px;
    ${ props => props.close
      ? `
          background-image: url('${DeleteIcon}');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          background-origin: content-box;

        `
      : `content: '\\2192';`
    }
  }
`;


class Events extends React.Component {
  constructor(props) {
    super(props);

    this.EVENT_STATE_COMING_SOON = 'comingSoon';
    this.EVENT_STATE_ONLINE = 'online';
    this.EVENT_STATE_OFFLINE = 'offline';

    const query = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true },
    );
    console.log('current', EventsData.current);
    console.log('offline', this.EVENT_STATE_OFFLINE)
    this.state = {
      eventStatus: EventsData.current
        ? this.EVENT_STATE_OFFLINE
        : this.EVENT_STATE_COMING_SOON,
      eventEnabled: query.live === 'true',
      formEnabled: false,
      formLoading: false,
      formData: {},
      formErrors: [],
      formSubmitted: false,
    };

    this.onInputUpdate = this.onInputUpdate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onInputUpdate(id, val) {
    const {formData} = this.state;

    this.setState({
      formData: {
        ...formData,
        [id]: val,
      },
    });
  }

  checkForm() {
    const { first_name, last_name, email } = this.state.formData;
    const formErrors = [];

    if ( !first_name ) formErrors.push('first_name');
    if ( !last_name ) formErrors.push('last_name');
    if ( !email ) formErrors.push('email');

    this.setState({ formErrors });

    return formErrors;
  }

  async onSubmit() {
    const { first_name, last_name, company, email } = this.state.formData;
    const errors = this.checkForm();

    if ( errors.length > 0) {
      return;
    }

    const { current } = EventsData
    if ( !current || !current.slug ) {
      console.error('No current event or slug available. abort');
      return;
    }

    const url = `https://api.b-reel.com/v1/events/${current.slug}/rsvps`;

    this.setState({formLoading: true });

    await post(url, {
      first_name,
      last_name,
      email,
      metadata: {
        company,
      }
    });

    this.setState({formSubmitted: true, formLoading: false });
  }

  getMetaData = (eventStatus) => {
    console.log(`event state: ${eventStatus}`);
    switch (eventStatus) {
      case this.EVENT_STATE_OFFLINE:
      case this.EVENT_STATE_ONLINE:
        const { current } = EventsData;
        return {
          title: RichText.asText([{ text: `B-Reel — ${current.share.title}` }]),
          description: RichText.asText([{
            text: current.share.description,
          }]),
          image: `https://www.b-reel.com/${current.share.image_url}`,
          themeColor: '#000'
        };
      case this.EVENT_STATE_COMING_SOON:
      default:
        return {
          title: RichText.asText([{ text: 'B-Reel — The Download' }]),
          description: RichText.asText([{
            text: `
              Check back soon for B-Reel's next session of The Download.
            `,
          }]),
          image: "https://www.b-reel.com/events/coming-soon.jpg",
          themeColor: '#000'
        };
    }
  };

  renderHero(eventStatus, data) {
    switch (eventStatus) {
      case this.EVENT_STATE_OFFLINE:
        return (
          <VideoPlayer url={data.teaser_video_url} loop={true} controls={false} />
        );
      case this.EVENT_STATE_ONLINE:
        return (
          <VideoPlayer url={data.live_video_url} loop={true} />
        );
      default:
        return (
          <Box mb={[5]} >
            <img src="/events/coming-soon.png" alt="Coming Soon" width="100%" />
          </Box>
        );
    }
  }
  render() {
    const {
      eventStatus,
      formEnabled,
      formSubmitted,
      eventEnabled,
      formErrors,
      formLoading,
    } = this.state;

    console.log(`event state: ${eventStatus}`);
    const currEventData = EventsData.current;
    const metaData = this.getMetaData(eventStatus);

    return (
      <Layout dark>
        <SEO {...metaData} />
        <IntroContainer>
          <Intro textAlign="center">
            <Box pb={[3, 7]} pt="3">
              <Text size={['2x', '3x', '3x']} color={colors.lime} textAlign="center">
                EVENTS
              </Text>
            </Box>

            { eventStatus !== this.EVENT_STATE_COMING_SOON
              ? <>
                  { eventStatus !== this.EVENT_STATE_ONLINE
                    ? <Box pb="3">
                        <UpcomingText size={['1x']} color={colors.lime} textAlign="center">
                          {currEventData.date}
                        </UpcomingText>
                      </Box>
                    : null
                  }

                  <Box pb="2" px={[0, 4, 8]}>
                    <Text size={['1.3x', '1.8x', '2x']} color={colors.white} textAlign="center">
                      {currEventData.title}
                    </Text>
                  </Box>
                </>
              : null
            }

            { this.renderHero(eventStatus, currEventData) }

            { eventStatus !== this.EVENT_STATE_COMING_SOON &&
              eventStatus !== this.EVENT_STATE_ONLINE
              ? <Box pb={[3, 4]} width="100%">

                  <Box>
                    <RsvpButton>RSVP</RsvpButton>
                  </Box>

                  <RsvpForm>
                    { !formSubmitted
                      ? <div>
                          <RsvpCloseIcon onClick={() => this.setState({ formEnabled: false })} />
                          {/* <Text size="1x" pb="2" color="lime">RSVP</Text> */}
                          <Input
                            label=""
                            theme="dark"
                            placeholder="First Name*"
                            id="first_name"
                            error={formErrors.includes('first_name')}
                            onUpdate={this.onInputUpdate}
                          />
                          <Input
                            label=""
                            theme="dark"
                            placeholder="Last Name*"
                            id="last_name"
                            error={formErrors.includes('last_name')}
                            onUpdate={this.onInputUpdate}
                          />
                          <Input
                            label=""
                            theme="dark"
                            placeholder="Company"
                            id="company"
                            error={formErrors.includes('company')}
                            onUpdate={this.onInputUpdate}
                          />
                          <Input
                            label=""
                            theme="dark"
                            placeholder="E-Mail*"
                            id="email"
                            error={formErrors.includes('email')}
                            onUpdate={this.onInputUpdate}
                          />
                          { formLoading
                            ? <Loader />
                            : <RsvpSubmitBtn onClick={this.onSubmit}>Submit</RsvpSubmitBtn>
                          }

                        </div>
                      : <div>

                          <RsvpCloseIcon onClick={() => this.setState({ formEnabled: false })} />
                          <Text size="1x" pb={[2, 3 ]}>All Set!<br />You have successfully reserved your spot for this event.</Text>
                          <Text size="1x" pb={[2, 4 ]}>Please check your email for confirmation.</Text>

                          <RsvpSubmitBtn
                            close={true}
                            onClick={() => this.setState({ formEnabled: false })}
                          >
                            Close
                          </RsvpSubmitBtn>

                        </div>
                      }
                  </RsvpForm>
                </Box>
              : null
            }


          </Intro>
        </IntroContainer>

        { eventStatus === this.EVENT_STATE_OFFLINE
          ? <Grid>
              <BorderBox gridColumn={['1 / -1', '1 / -1']} textAlign="center" pt={[3, 6]} pb={[3, 4]} px={[1, 7]}>
                {console.log('split', currEventData.description.split(`\n\n`))}
                {currEventData.description.split(`\n\n`).map(txt => (
                  <Text size="0.7x" py="20px" dangerouslySetInnerHTML={{ __html: txt }}></Text>
                ))}
              </BorderBox>
            </Grid>
          : null
        }

        <Grid>
          <BorderBox gridColumn={['1 / -1', '3 / span 8']} textAlign="center" pt={[3, 6]} pb={[3, 8]} px={[1, 7]}>
            <Text size="1" py="20px" textAlign="center" pb={5}>
              PREVIOUS EVENTS
            </Text>
            {EventsData.archive.map( evt => (
              <>
                <a href={evt.archive.video_url} target="_blank">
                  <img src={evt.archive.poster_url}
                    width="100%"
                    style={{ maxWidth: 500 }}
                    alt="How to Reinvent UGC for a TikTok Generation"
                  />
                </a>
                <Text size="0.8x" pt="20px" pb="60px" px={[ 0, '50px', '160px']} textAlign="center">
                  <a href={evt.archive.video_url} target="_blank">{evt.archive.title}</a>
                </Text>
              </>
            ))}
          </BorderBox>
          {/*
          <Box gridColumn={['1 / -1', '3 / span 8']} textAlign="left" pt={[3, 5]} pb={[3, 8]} px={[1, 7]}>
            <Text size="0.8x" py="20px">
            Please join us for a talk by B-Reel creative team, Pieter Konickx and Suchi Ahuja, on ‘How to design a flagship experience with no store’. From the team that launched Rihanna's luxury Maison FENTY as a digital first fashion brand, they will be sharing their knowledge on how to build modern fashion brands and revolutionize the shopping experience through design, for an increasingly digital fashion world.
            </Text>
          </Box>
          */}
        </Grid>
      </Layout>
    );
  }
};

export default Events;


