export default {
  current: null,
  temp: {
    slug: 'virtual-event-internet-of-luxury',
    date: 'JANUARY 27th, 2021 12PM EDT',
    title: 'The Download — Internet of Luxury',
    description: `
      We are proud to introduce Internet of Luxury, a new collaborative project
      from B-Reel that explores how luxury brands can harness culture and
      technology to envision new realities in this hyper-digital moment,
      incorporating proprietary data and expert voices from across the industry.

      To mark the launch, we’ll be hosting an online event to introduce the
      project, share key insights, and data through a moderated discussion with
      some of our expert contributors. We’ll be joined by award-winning futurist,
      Lucie Greene, B-Reel’s Global Chief Strategy Officer, Melissa Jackson-Parsey,
      CEO of <a href="https://holition.com/" target="_blank">Holition</a>, Jonathan
      Chippindale, Founder and chief data officer of
      <a href="https://www.sefleuria.com/" target="_blank">Sefleuria</a>, Jessica
      Graves, Co-founder of digital fashion marketplace
      <a href="https://thedematerialised.com/" target="_blank">THE DEMATERIALISED</a>,
      Karinna Nobbs, and Managing Director, North America of
      <a href="https://whisprgroup.com/" target="_blank">Whispr Group</a>, Cronja Hultin.

      Join us on January 27 at 12pm EST to explore what comes next in luxury and digital.
    `,
    live_video_url: '',
    teaser_video_url: '/media/events-iol.mp4',
    share: {
      title: 'The Download — Internet of Luxury',
      image_url: '/events//virtual-event-internet-of-luxury/share.jpg',
      description: `
        We are proud to introduce Internet of Luxury, a new collaborative project
        from B-Reel that explores how luxury brands can harness culture and
        technology to envision new realities in this hyper-digital moment, incorporating
        proprietary data and expert voices from across the industry.
      `,
      title: 'The Download — Internet of Luxury',
    },
    archive: {
      video_url: 'https://youtu.be/ZUNFWY7akmg',
      poster_url: '/events/virtual-event-fenti/archive-poster.png',
      title: 'How to Reinvent UGC for a TikTok Generation',
    },
  },
  archive: [
    {
      slug: 'virtual-event-internet-of-luxury',
      date: 'JANUARY 27th, 2021 12PM EDT',
      title: 'The Download — Internet of Luxury',
      description: `
        We are proud to introduce Internet of Luxury, a new collaborative project
        from B-Reel that explores how luxury brands can harness culture and
        technology to envision new realities in this hyper-digital moment,
        incorporating proprietary data and expert voices from across the industry.

        To mark the launch, we’ll be hosting an online event to introduce the
        project, share key insights, and data through a moderated discussion with
        some of our expert contributors. We’ll be joined by award-winning futurist,
        Lucie Greene, B-Reel’s Global Chief Strategy Officer, Melissa Jackson-Parsey,
        CEO of <a href="https://holition.com/" target="_blank">Holition</a>, Jonathan
        Chippindale, Founder and chief data officer of
        <a href="https://www.sefleuria.com/" target="_blank">Sefleuria</a>, Jessica
        Graves, Co-founder of digital fashion marketplace
        <a href="https://thedematerialised.com/" target="_blank">THE DEMATERIALISED</a>,
        Karinna Nobbs, and Managing Director, North America of
        <a href="https://whisprgroup.com/" target="_blank">Whispr Group</a>, Cronja Hultin.

        Join us on January 27 at 12pm EST to explore what comes next in luxury and digital.
      `,
      live_video_url: '',
      teaser_video_url: '/media/events-iol.mp4',
      share: {
        title: 'The Download — Internet of Luxury',
        image_url: '/events/virtual-event-internet-of-luxury/share.jpg',
        description: `
          We are proud to introduce Internet of Luxury, a new collaborative project
          from B-Reel that explores how luxury brands can harness culture and
          technology to envision new realities in this hyper-digital moment, incorporating
          proprietary data and expert voices from across the industry.
        `,
        title: 'The Download — Internet of Luxury',
      },
      archive: {
        video_url: 'https://youtu.be/ZUNFWY7akmg',
        poster_url: '/events/virtual-event-internet-of-luxury/archive-poster.jpg',
        title: 'Internet of Luxury',
      },
    }, {
      slug: 'virtual-event-tik-tok-generation',
      archive: {
        video_url: 'https://youtu.be/eByBKOt2p1E',
        poster_url: '/events/virtual-event-tik-tok-generation/archive-poster.jpg',
        title: 'How to Reinvent UGC for a TikTok Generation',
      },
    }, {
      slug: 'virtual-event-fenti',
      archive: {
        video_url: 'https://youtu.be/8sqODXi-6w0',
        poster_url: '/events/virtual-event-fenti/archive-poster.jpg',
        title: 'How to Build a flagship experience with no store',
      },
    },
  ]
}